import { calculateCurrentPrice } from "./variation";

export const calculateCartTotal = ({
  cartItems,
  coupon,
  pricingData = {},
  showAsDiscounted = false,
}) => {
  let isCustomPrice = false;
  let isMinimumQtyDone = true;
  const totals = cartItems?.reduce(
    (total, item) => {
      let { price, discountPrice } = calculateCurrentPrice(
        item.selectedVariant,
        item.quantity,
        coupon,
        cartItems
      );

      if (
        item.selectedVariant &&
        pricingData?.variantCustomPrices?.hasOwnProperty(
          item.selectedVariant?.id
        )
      ) {
        if (showAsDiscounted) {
          discountPrice =
            pricingData.variantCustomPrices[item.selectedVariant?.id].price;
        } else {
          price =
            pricingData.variantCustomPrices[item.selectedVariant?.id].price;
        }
        isCustomPrice = true;
        if (
          item.quantity <
          pricingData.variantCustomPrices[item.selectedVariant.id].itemMoq
        ) {
          isMinimumQtyDone = false;
        }
      } else if (
        item.selectedVariant &&
        pricingData?.productCustomPrices?.hasOwnProperty(
          item.selectedVariant.parentId
        )
      ) {
        if (showAsDiscounted) {
          discountPrice =
            pricingData.productCustomPrices[item.selectedVariant.parentId]
              .price;
        } else {
          price =
            pricingData.productCustomPrices[item.selectedVariant.parentId]
              .price;
        }
        isCustomPrice = true;
        const productQty = cartItems.reduce((total, _item) => {
          if (
            item.selectedVariant.parentId === _item.selectedVariant.parentId
          ) {
            return total + item.quantity;
          } else {
            return total;
          }
        }, 0);
        if (
          productQty <
          pricingData.productCustomPrices[item.selectedVariant.parentId].itemMoq
        ) {
          isMinimumQtyDone = false;
        }
      }
      const lineItemPrice = price * item.quantity;
      const lineItemDiscountPrice = discountPrice * item.quantity;
      return {
        cartSubTotal: total.cartSubTotal + lineItemPrice,
        discountSubTotal: total.discountSubTotal + lineItemDiscountPrice,
        isCustomPrice: isCustomPrice,
        isMinimumQtyDone: isMinimumQtyDone,
      };
    },
    { cartSubTotal: 0, discountSubTotal: 0 }
  );

  if (
    !coupon?.hasMinimumOrderPrice ||
    parseFloat(coupon?.minimumOrderPrice) < totals?.cartSubTotal
  ) {
    return {
      cartSubTotal: totals?.cartSubTotal,
      discountSubTotal: totals?.discountSubTotal,
      discount: totals?.cartSubTotal - totals?.discountSubTotal,
      isCustomPrice: totals?.isCustomPrice,
      isMinimumQtyDone: totals?.isMinimumQtyDone,
    };
  }

  return {
    cartSubTotal: totals?.cartSubTotal,
    discountSubTotal: totals?.cartSubTotal,
    discount: 0,
    isCustomPrice: totals?.isCustomPrice,
    isMinimumQtyDone: totals?.isMinimumQtyDone,
  };
};

export const getCartProducts = (cartData) => {
  return cartData.cart.map((item) => ({
    id: item.selectedVariant.id,
    quantity: item.quantity,
  }));
};
