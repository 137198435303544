import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_PRODUCTS_REQUEST,
  fetchProductsSuccess,
} from "../actions/productActions";
import axios, { AxiosRequestConfig } from "axios";

export const convert = (product) => {
  return {
    id: product.id,
    //@ts-ignore
    // sku: product.inventoryDetails.sku as string,
    name: product.name,
    slug: product.id,
    approved: product.published,
    // stock:
    //   product.inventoryDetails.stock ||
    //   product.inventoryDetails.totalStock ||
    //   ("0" as string),
    price: parseFloat(product.Inventories[0].priceRangeArray[0].price),
    // discount: 0,
    featured: false,
    new: false,
    // rating: product.averageRating || 0,
    // ratingCount: product.reviewCount || 0,
    // saleCount: product.saleCount || 0,
    category: product?.Category?.name,
    categoryId: product?.categoryId,
    // tag: product.tag || (null as Array<string> | null),
    attributes:
      Array.isArray(product.attributes) &&
      product.attributes.length > 0 &&
      product.attributes[0]
        ? product.attributes
        : null || null,
    variations:
      Array.isArray(product.Inventories) && product.Inventories.length > 0
        ? product.Inventories
        : null || null,
    variation: null,
    thumbImage: Array.isArray(product.images)
      ? product.images || null
      : [product.images || null],
    image: Array.isArray(product.images)
      ? product.images || null
      : [product.images || null],
    // shortDescription: product.shortDescription || (null as string | null),
    // fullDescription:
    // product.inventoryDetails.description || (null as string | null),
    // inventoryId: product.inventoryId as string,
    // priceRangeArray: product.inventoryDetails.priceRangeArray || null,
  };
};

function* fetchProductsSaga(action) {
  if (typeof window !== "undefined") {
    try {
      const requestConfig = {
        method: "GET",
        url: `${process.env.NEXT_PUBLIC_API_BASE}products`,
      };
      const response = yield call(axios, requestConfig);

      const productsData = response.data.products;

      yield put(
        fetchProductsSuccess(
          productsData
            .filter((product) => product.Inventories.length > 0)
            .map(convert)
        )
      );
    } catch (e) {
      console.error(e);
      yield put({ type: "FETCH_PRODUCTS_FAILED", message: e });
    }
  }
}
function* productSaga() {
  yield takeEvery(FETCH_PRODUCTS_REQUEST, fetchProductsSaga);
}

export default productSaga;
