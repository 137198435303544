import { fork, all } from "redux-saga/effects";
import productSaga from "./productSaga";
import userSaga from "./userSaga";
import checkoutSaga from "./checkoutSaga";
import addressSaga from "./addressSaga";
import ordersSaga from "./ordersSaga";
import reviewSaga from "./reviewSaga";

export default function* rootSaga() {
  yield all([
    fork(productSaga),
    fork(userSaga),
    fork(checkoutSaga),
    fork(addressSaga),
    fork(ordersSaga),
    fork(reviewSaga),
  ]);
}
