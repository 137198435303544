import {
  CREATE_ADDRESS_FAIL,
  CREATE_ADDRESS_SUCCESS,
  CREATE_ADDRESS_TRIGGER,
  UPDATE_ADDRESS_FAIL,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_TRIGGER,
  CLEAR,
  GET_ALL_ADDRESSES_SUCCESS,
  GET_ALL_ADDRESSES_TRIGGER,
  GET_ALL_ADDRESSES_FAIL,
} from "../actions/addressActions";

import { LOGOUT } from "../types";

const initState = {
  getAllAddressesLoading: false,
  addresses: [],
  getAllAddressesFailed: false,
  updateAddressLoading: false,
  updateAddressFailed: false,
  updateAddressSuccess: false,
  createAddressLoading: false,
  createAddressFailed: false,
  createAddressSuccess: false,
  errorMessage: "",
};

const addressReducer = (state = initState, action) => {
  switch (action.type) {
    case CREATE_ADDRESS_TRIGGER:
      return {
        ...state,
        createAddressLoading: true,
      };
    case CREATE_ADDRESS_SUCCESS: {
      const { addresses } = action.payload;
      return {
        ...state,
        createAddressLoading: false,
        createAddressSuccess: true,
        ...addresses,
      };
    }
    case CREATE_ADDRESS_FAIL: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        createAddressLoading: false,
        createAddressFailed: true,
        errorMessage,
      };
    }
    case UPDATE_ADDRESS_TRIGGER:
      return {
        ...state,
        updateAddressLoading: true,
      };
    case UPDATE_ADDRESS_SUCCESS: {
      const { addresses } = action.payload;
      return {
        ...state,
        updateAddressSuccess: true,
        updateAddressLoading: false,
        ...addresses,
      };
    }
    case UPDATE_ADDRESS_FAIL: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        updateAddressLoading: false,
        updateAddressFailed: true,
        errorMessage,
      };
    }
    case GET_ALL_ADDRESSES_TRIGGER:
      return {
        ...state,
        getAllAddressesLoading: true,
      };
    case GET_ALL_ADDRESSES_SUCCESS: {
      const { addresses } = action.payload;
      return {
        ...state,
        getAllAddressesLoading: false,
        addresses,
      };
    }
    case GET_ALL_ADDRESSES_FAIL: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        getAllAddressesLoading: false,
        getAllAddressesFailed: true,
        errorMessage,
      };
    }
    case CLEAR:
      return {
        ...state,
        getAllAddressesFailed: false,
        updateAddressFailed: false,
        createAddressFailed: false,
        updateAddressSuccess: false,
        createAddressSuccess: false,
        updateAddressLoading: false,
        createAddressLoading: false,
        errorMessage: "",
      };
    case LOGOUT:
      return { ...initState };
    default:
      return state;
  }
};

export default addressReducer;
