export const CREATE_ADDRESS_TRIGGER = "CREATE_ADDRESS_TRIGGER";
export const CREATE_ADDRESS = "CREATE_ADDRESS";
export const CREATE_ADDRESS_SUCCESS = "CREATE_ADDRESS_SUCCESS";
export const CREATE_ADDRESS_FAIL = "CREATE_ADDRESS_FAIL";

export const UPDATE_ADDRESS_TRIGGER = "UPDATE_ADDRESS_TRIGGER";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_FAIL = "UPDATE_ADDRESS_FAIL";

export const GET_ALL_ADDRESSES_TRIGGER = "GET_ALL_ADDRESSES_TRIGGER";
export const GET_ALL_ADDRESSES = "GET_ALL_ADDRESSES_";
export const GET_ALL_ADDRESSES_SUCCESS = "GET_ALL_ADDRESSES_SUCCESS";
export const GET_ALL_ADDRESSES_FAIL = "GET_ALL_ADDRESSES_FAIL";

export const CLEAR = "ADDRESS/CLEAR";

export function createAddressTrigger() {
  return {
    type: CREATE_ADDRESS_TRIGGER,
  };
}

export function createAddress(
  addressLine1,
  addressLine2,
  postcode,
  townCity,
  country,
  address_type,
  pageName,
) {
  return {
    type: CREATE_ADDRESS,
    payload: {
      addressLine1,
      addressLine2,
      postcode,
      townCity,
      country,
      address_type,
      pageName,
    },
  };
}

export function createAddressSuccess(addresses) {
  return {
    type: CREATE_ADDRESS_SUCCESS,
    payload: {
      addresses,
    },
  };
}

export function createAddressFail(errorMessage) {
  return {
    type: CREATE_ADDRESS_FAIL,
    payload: {
      errorMessage,
    },
  };
}

export function updateAddressTrigger() {
  return {
    type: UPDATE_ADDRESS_TRIGGER,
  };
}

export function updateAddress(
  id,
  addressLine1,
  addressLine2,
  postcode,
  townCity,
  country,
  address_type,
  _storage_type,
  pageName,
) {
  return {
    type: UPDATE_ADDRESS,
    payload: {
      id,
      addressLine1,
      addressLine2,
      postcode,
      townCity,
      country,
      address_type,
      _storage_type,
      pageName,
    },
  };
}

export function updateAddressSuccess(addresses) {
  return {
    type: UPDATE_ADDRESS_SUCCESS,
    payload: {
      addresses,
    },
  };
}

export function updateAddressFail(errorMessage) {
  return {
    type: UPDATE_ADDRESS_FAIL,
    payload: {
      errorMessage,
    },
  };
}

export function getAllAddressTrigger() {
  return {
    type: GET_ALL_ADDRESSES_TRIGGER,
  };
}

export function getAllAddress() {
  return {
    type: GET_ALL_ADDRESSES,
  };
}

export function getAllAddressSuccess(addresses) {
  return {
    type: GET_ALL_ADDRESSES_SUCCESS,
    payload: { addresses },
  };
}

export function getAllAddressFail(errorMessage) {
  return {
    type: GET_ALL_ADDRESSES_FAIL,
    payload: {
      errorMessage,
    },
  };
}

export function clear() {
  return {
    type: CLEAR,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}
