import * as actionTypes from "../constants/cartConstants";
import axios from "axios";
import { getCartProducts } from "../../lib/cart";

export const updateCart = () => async (dispatch, getState) => {
  const userData = getState().userData;
  const cartData = getState().cartData;
  const { idToken } = userData;

  if (userData.authenticated) {
    const { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_API_BASE}cart`,
      {
        products: getCartProducts(cartData),
      },
      {
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }
    );
  }

  localStorage.setItem("cart", JSON.stringify(cartData));
};

export const addToCart =
  ({ price, addToast, quantity, selectedVariant }) =>
  async (dispatch, getState) => {
    dispatch({
      type: actionTypes.ADD_TO_CART,
      payload: {
        quantity,
        price,
        selectedVariant,
      },
    });

    addToast("Added To Cart", { appearance: "success", autoDismiss: true });
    dispatch(updateCart());
  };

export const addToCartMultiple =
  ({ items, addToast }) =>
  async (dispatch, getState) => {
    dispatch({
      type: actionTypes.ADD_TO_CART_MULTIPLE,
      payload: {
        items,
      },
    });
    addToast("Added To Cart", { appearance: "success", autoDismiss: true });
    dispatch(updateCart());
    const couponCode = getState().cartData.coupon.code;
    const cartItems = getState().cartData.cart;
    if (couponCode !== undefined) {
      dispatch(applyCoupon(couponCode, cartItems));
    }
  };

export const removeFromCart =
  (product, addToast) => async (dispatch, getState) => {
    if (addToast) {
      addToast("Removed From Cart", { appearance: "error", autoDismiss: true });
    }
    dispatch({
      type: actionTypes.REMOVE_FROM_CART,
      payload: product,
    });

    dispatch(updateCart());
  };

export const resetCart = (addToast) => async (dispatch) => {
  if (addToast) {
    addToast("Cart Cleared", { appearance: "error", autoDismiss: true });
  }
  dispatch({
    type: actionTypes.CART_RESET,
  });

  localStorage.removeItem("cart");
  dispatch(updateCart());
  dispatch(removeCoupon());
};

export const cartFromStorage = (cart) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.CART_FROM_STORAGE,
    payload: cart,
  });
};

export const getCart = () => async (dispatch, getState) => {
  const userData = getState().userData;
  const productData = getState().productData;
  const idToken = userData.idToken;

  const { data } = await axios.get(`${process.env.NEXT_PUBLIC_API_BASE}cart`, {
    headers: {
      Authorization: idToken,
    },
  });
  const storedCart = JSON.parse(data.products);

  dispatch({
    type: actionTypes.GET_CART,
    payload: {
      storedCart,
    },
  });
};

export const changeQuantity =
  (newQty, item, addToast, newPrice) => (dispatch, getState) => {
    dispatch({
      type: actionTypes.CHANGE_QUANTITY,
      payload: {
        newQty,
        item,
        newPrice,
      },
    });
    addToast("Cart Updated", { appearance: "success", autoDismiss: true });
    dispatch(updateCart());
  };

export const applyCoupon = (couponCode) => async (dispatch, getState) => {
  const userData = getState().userData;
  const { idToken, authenticated } = userData;

  let url = "cart/coupon";
  let options = {
    headers: {
      Authorization: "Bearer " + idToken,
    },
  };

  if (!authenticated) {
    url = "cart/guest/coupon";
    options = {};
  }

  try {
    const { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_API_BASE}${url}`,
      {
        couponCode: couponCode,
      },
      { ...options }
    );
    dispatch({
      type: actionTypes.APPLY_COUPON,
      payload: {
        data,
      },
    });
    dispatch(updateCart());
  } catch (error) {
    console.log(error);
  }
};

export const removeCoupon = () => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_COUPON,
  });
  dispatch(updateCart());
};
