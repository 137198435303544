import { call, put, takeEvery, select, take } from "redux-saga/effects";
import {
  registerUserSuccess,
  registerUserFail,
  loginUserSuccess,
  loginUserFail,
  getUserDetailsSuccess,
  getUserDetailsFail,
  updateUserSuccess,
  updateUserFail,
  UPDATE_ID_TOKEN_REQUEST,
  updateIdTokenSuccess,
  CHECKOUT,
  checkoutFail,
  checkoutSuccess,
  getUserDetails,
  final_logout,
  forgotPasswordSuccess,
  GUEST_CHECKOUT,
  CUSTOM_CHECKOUT,
  forgotPasswordFailure,
} from "../actions/userActions";
import {
  FORGOT_PASSWORD,
  LOGIN_USER,
  REGISTER_USER,
  UPDATE_USER_DETAILS,
  GET_USER_DETAILS,
} from "../types";
import { getCart, resetCart } from "../actions/cartActions";
import axios from "axios";
import firebase from "firebase/app";
import {
  firebaseAuth,
  createUserWithEmailAndPassword,
} from "../../../firebase";
import {
  EmailAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signOut,
  getIdToken,
  getAuth,
} from "firebase/auth";
import { clearCards } from "../actions/checkoutActions";
import { getCartProducts } from "../../lib/cart";
import { eventChannel } from "redux-saga";

export async function generateIdToken() {
  let tokenData = undefined;
  const currentUser = getAuth()?.currentUser;
  if (currentUser) {
    tokenData = await getIdToken(currentUser, true);
  }
  return tokenData;
}

function* registerUserSaga(action) {
  try {
    const { email, password, firstName, lastName } = action.payload;
    const cartData = yield select((state) => state.cartData);

    const userCredential = yield createUserWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );

    const userToken = userCredential.user.accessToken;

    const requestConfig = {
      method: "POST",
      url: `${process.env.NEXT_PUBLIC_API_BASE}users/signup`,
      data: {
        email,
        firstName,
        lastName,
        products: getCartProducts(cartData),
      },
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    //@ts-ignore
    const response = yield call(axios, requestConfig);
    const { id } = response.data;
    const profileData = { email, firstName, lastName, uid: id };

    //! Custom token can likely be removed
    const customToken = id;
    yield put(registerUserSuccess(customToken, userToken, profileData));
    // yield put(getUserDetails());
  } catch (e) {
    console.error(e);
    let errorMessage = "";
    if (e.response && e.response.data) {
      errorMessage = e.response.data;
      //@ts-ignore
      if (errorMessage.errorInfo && errorMessage.errorInfo.message) {
        //@ts-ignore
        errorMessage = errorMessage.errorInfo.message;
      }
    }
    yield put(registerUserFail(errorMessage));
  }
}

function* loginUserSaga(action) {
  try {
    const { email, password } = action.payload;
    const userCredential = yield signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );

    const userToken = userCredential.user.accessToken;
    const requestConfig = {
      method: "GET",
      url: `${process.env.NEXT_PUBLIC_API_BASE}users`,
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };

    //@ts-ignore
    const response = yield call(axios, requestConfig);
    const { id } = response.data;

    const customToken = id;
    yield put(loginUserSuccess(customToken, userToken));
    //@ts-ignore
    // yield put(getCart());
    // yield put(getUserDetails());
  } catch (e) {
    let errorMessage = "";
    if (e.response && e.response.data) {
      errorMessage = e.response.data;
      //@ts-ignore
      if (errorMessage.errorInfo && errorMessage.errorInfo.message) {
        //@ts-ignore
        errorMessage = errorMessage.errorInfo.message;
      }
    } else if (e.code && e.code === "auth/invalid-email") {
      errorMessage = "Email does not Exists!";
    } else if (e.code && e.code !== "") {
      const str = e.code;
      errorMessage = `${str[0].toUpperCase()}${str.slice(1)}`;
    }
    errorMessage = errorMessage === "" ? "Something Went Wrong" : errorMessage;
    yield put(loginUserFail(errorMessage));
  }
}

function* getUserDetailsSaga(action) {
  try {
    const userData = yield select((state) => state.userData);
    const { authenticated } = userData;
    const { customToken, idToken } = userData;
    // const idToken = yield generateIdToken();
    const requestConfig = {
      method: "GET",
      url: `${process.env.NEXT_PUBLIC_API_BASE}user`,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    //@ts-ignore
    const response = yield call(axios, requestConfig);
    yield put(getUserDetailsSuccess(response.data));
  } catch (e) {
    console.error(e);
    let errorMessage = "";
    if (e.response && e.response.data) {
      errorMessage = e.response.data;
      //@ts-ignore
      if (errorMessage.errorInfo && errorMessage.errorInfo.message) {
        //@ts-ignore
        errorMessage = errorMessage.errorInfo.message;
      }
    }
    yield put(getUserDetailsFail(errorMessage));
  }
}

function* updateUserDetailsSaga(action) {
  try {
    const userData = yield select((state) => state.userData);
    const { customToken } = userData;
    // const idToken = yield generateIdToken();
    const idToken = yield getIdToken(getAuth().currentUser, true);
    console.log("here inside saga", action.payload, idToken);
    const { email, firstName, lastName, password = "" } = action.payload;
    const requestConfig = {
      method: "PUT",
      url: `${process.env.NEXT_PUBLIC_API_BASE}users/updateUser`,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      data: {
        email,
        firstName,
        lastName,
        ...(password !== "" && { password }),
      },
    };
    //@ts-ignore
    const response = yield call(axios, requestConfig);
    const { success = "", message = "" } = response.data;
    if (success == "") {
      yield put(updateUserSuccess({ message: "Something went wrong!" }));
    } else if (success) {
      const data = { message, ...action.payload };
      yield put(updateUserSuccess(data));
    }
  } catch (e) {
    console.error(e);
    let errorMessage = "";
    if (e.response && e.response.data) {
      errorMessage = e.response.data;
      //@ts-ignore
      if (errorMessage.errorInfo && errorMessage.errorInfo.message) {
        //@ts-ignore
        errorMessage = errorMessage.errorInfo.message;
      }
    }
    yield put(updateUserFail(errorMessage));
  }
}

function* logoutSaga() {
  yield signOut(firebaseAuth);
  yield put(final_logout());
}

function* passwordResetSaga(action) {
  try {
    console.info("inside forgot password saga", action.payload.email);
    const { email = "" } = action?.payload;
    const requestConfig = {
      method: "POST",
      url: `${process.env.NEXT_PUBLIC_API_BASE}user/reset/auth/forgot-password`,
      data: {
        email,
      },
    };
    const response = yield call(axios, requestConfig);
    const { success, error = "", message = "" } = response?.data;
    console.log("api response from forgot password saga", response);
    if (success) yield put(forgotPasswordSuccess({ successMessage: message }));
    else yield put(forgotPasswordFailure({ errorMessage: error }));
  } catch (e) {
    console.error("error in forgot password", e);
    console.log(e);
    yield put(
      forgotPasswordFailure({
        errorMessage:
          "Failed to send password reset link please contact admin!",
      })
    );
  }
}

function* userSaga() {
  yield takeEvery(REGISTER_USER, registerUserSaga);
  yield takeEvery(LOGIN_USER, loginUserSaga);
  yield takeEvery(GET_USER_DETAILS, getUserDetailsSaga);
  yield takeEvery(UPDATE_USER_DETAILS, updateUserDetailsSaga);
  // yield takeEvery(UPDATE_ID_TOKEN_REQUEST, updateIdTokenSaga);
  yield takeEvery("LOGOUT_TRIGGER", logoutSaga);
  yield takeEvery(FORGOT_PASSWORD, passwordResetSaga);
}

export default userSaga;
