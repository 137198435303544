import axios from "axios";
import { put, select, takeEvery, takeLeading } from "redux-saga/effects";
import { getCartProducts } from "../../lib/cart";
import { resetCart } from "../actions/cartActions";

import {
  GET_ACCESS_TOKEN,
  GET_ACCESS_TOKEN_ASYNC,
  POST_CHARGE,
  POST_EXISTING_SHOP_ORDER_CHARGE,
} from "../actions/checkoutActions";
import { checkoutFail, checkoutSuccess } from "../actions/userActions";

function* getAccessTokenAsync() {
  try {
    const { data } = yield axios.get(
      `${process.env.NEXT_PUBLIC_API_BASE}checkout/access-token`
    );

    yield put({ type: GET_ACCESS_TOKEN_ASYNC, payload: data });
  } catch (e) {
    console.error(e);
  }
}

function* postCharge(action) {
  const {
    data: { chargeToken },
    guestUser,
  } = action.payload;
  const userData = yield select((state) => state.userData);
  const cartData = yield select((state) => state.cartData);
  const addressData = yield select((state) => state.addressData);

  const { idToken, authenticated } = userData;

  let url = "checkout/charge";
  let options = {
    headers: {
      Authorization: "Bearer " + idToken,
    },
  };

  if (!authenticated) {
    url = "checkout/guest/charge";
    options = {};
  }

  try {
    const { data } = yield axios.post(
      `${process.env.NEXT_PUBLIC_API_BASE}${url}`,
      {
        chargeToken,
        products: getCartProducts(cartData),
        coupon: cartData?.coupon.id,
        addresses: addressData.addresses,
        user: guestUser,
      },
      {
        ...options,
      }
    );

    yield put(checkoutSuccess(data));
    yield put(resetCart());
  } catch (e) {
    yield put(
      checkoutFail(
        "Payment refused. Please contact support if this happens again."
      )
    );
  }
}

function* postExistingShopOrderCharge(action) {
  console.log("Inside dispatch");
  const {
    shopOrderId,
    data: { chargeToken },
  } = action.payload;
  const userData = yield select((state) => state.userData);
  const { idToken, authenticated } = userData;

  let url = "checkout/charge/existing-order";
  let options = {
    headers: {
      Authorization: "Bearer " + idToken,
    },
  };

  try {
    const { data } = yield axios.post(
      `${process.env.NEXT_PUBLIC_API_BASE}${url}`,
      {
        chargeToken,
        shopOrderId,
      },
      {
        ...options,
      }
    );

    yield put(checkoutSuccess(data));
    yield put(resetCart());
  } catch (e) {
    yield put(
      checkoutFail(
        "Payment refused. Please contact support if this happens again."
      )
    );
  }
}

// Root saga for payments
function* paymentSaga() {
  yield takeEvery(GET_ACCESS_TOKEN, getAccessTokenAsync);
  yield takeLeading(POST_CHARGE, postCharge);
  yield takeLeading(
    POST_EXISTING_SHOP_ORDER_CHARGE,
    postExistingShopOrderCharge
  );
}

export default paymentSaga;
