import { GET_ACCESS_TOKEN_ASYNC } from "../actions/checkoutActions";

const initialCheckout = {
  accessToken: "",
  orderNumber: "",
  orderCreateSuccess: false,
  orderId: "",
  transactionChecked: false,
  paymentSuccess: false,
  statusId: "",
};

const checkoutReducer = (state = initialCheckout, action) => {
  switch (action.type) {
    // case actionTypes.CREATE_PAYMENT:
    //   return {
    //     ...state,
    //     orderNumber: action.payload.viva.OrderCode,
    //     orderCreateSuccess: action.payload.viva.Success,
    //   };

    // case actionTypes.CHECK_TRANSACTION:
    //   return {
    //     ...state,
    //     transactionChecked: true,
    //     paymentSuccess: action.payload.paymentSuccess,
    //     statusId: action.payload.statusId,
    //   };

    case GET_ACCESS_TOKEN_ASYNC:
      return { ...state, accessToken: action.payload };

    default:
      return state;
  }
};

export default checkoutReducer;
