import * as actionTypes from "../constants/cartConstants";

// Check local storage for a cart for intial state
const initialCart = { cart: [], coupon: { discount: 0 } };

const cartReducer = (state = initialCart, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_CART:
      const newItem = action.payload;
      const existItem = state.cart.find(
        (item) => item.selectedVariant.id === newItem.id
      );
      return {
        ...state,
        cart: [...state.cart, newItem],
      };
    case actionTypes.ADD_TO_CART_MULTIPLE:
      const { items } = action.payload;
      return {
        ...state,
        cart: [...state.cart, ...items],
      };
    case actionTypes.CART_RESET:
      return {
        ...state,
        cart: [],
      };

    case actionTypes.REMOVE_FROM_CART:
      const removeItem = action.payload;
      if (removeItem.selectedVariant) {
        const newState = state.cart.filter(
          (cart) => cart.selectedVariant.id !== removeItem.selectedVariant.id
        );
        if (newState.length === 0)
          return {
            ...state,
            cart: [],
          };
        else
          return {
            ...state,
            cart: newState,
          };
      } else {
        const newState = state.cart.filter((cart) => cart.id !== removeItem.id);
        if (newState.length === 0) return { ...state, cart: [] };
        else
          return {
            ...state,
            cart: newState,
          };
      }

    case actionTypes.CART_FROM_STORAGE:
      const cart = action.payload;
      return {
        ...state,
        cart: [...state.cart, ...cart],
      };

    case actionTypes.GET_CART:
      const storedCart = action.payload.storedCart.cart;
      return { ...state, cart: [...state.cart, ...storedCart] };

    case actionTypes.CHANGE_QUANTITY:
      const { newQty, newPrice } = action.payload;
      const changeItem = action.payload.item;
      if (changeItem.selectedVariant) {
        return {
          ...state,
          cart: state.cart.map((item) =>
            item.selectedVariant.id === changeItem.selectedVariant.id
              ? { ...item, quantity: newQty, price: newPrice }
              : item
          ),
        };
      } else {
        return {
          ...state,
          cart: state.cart.map((item) =>
            item.id === changeItem.id ? { ...item, quantity: newQty } : item
          ),
        };
      }

    case actionTypes.APPLY_COUPON:
      return {
        ...state,
        coupon: action.payload.data,
      };

    case actionTypes.REMOVE_COUPON:
      return {
        ...state,
        coupon: { discount: 0 },
      };

    default:
      return state;
  }
};

export default cartReducer;
