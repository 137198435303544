import {
  CLEAR,
  GET_ALL_ORDERS_SUCCESS,
  GET_ALL_ORDERS_TRIGGER,
  GET_ALL_ORDERS_FAIL,
} from "../actions/orderActions";

import { LOGOUT } from "../types";

const initState = {
  getAllOrdersLoading: false,
  orders: [],
  getAllOrdersFailed: false,
  errorMessage: "",
};

const userOrdersReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_ORDERS_TRIGGER:
      return {
        ...state,
        getAllOrdersLoading: true,
      };
    case GET_ALL_ORDERS_SUCCESS:
      const { orders } = action.payload;
      return {
        ...state,
        getAllOrdersLoading: false,
        orders,
      };
    case GET_ALL_ORDERS_FAIL:
      const { errorMessage } = action.payload;
      return {
        ...state,
        getAllOrdersLoading: false,
        getAllOrdersFailed: true,
        errorMessage,
      };
    case CLEAR:
      return {
        ...state,
        getAllOrdersFailed: false,
        errorMessage: "",
      };

    case LOGOUT:
      return initState;
    default:
      return state;
  }
};

export default userOrdersReducer;
