import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  GET_ALL_ORDERS,
  getAllOrdersSuccess,
  getAllOrdersFail,
} from "../actions/orderActions";
import axios from "axios";
import { generateIdToken } from "./userSaga";

function* getAllOrdersSaga(action) {
  try {
    const userData = yield select((state) => state.userData);
    const productData = yield select((state) => state.productData);
    const { customToken, authenticated, profileData } = userData;
    const idToken = yield generateIdToken();
    const { filterData:{page, size} } = action.payload;

    const url = `${process.env.NEXT_PUBLIC_API_BASE}orders/${profileData?.uid || profileData?.id}?page=${page}&size=${size}`;
    const requestConfig = {
      method: "GET",
      url,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      data: {
        page, size,
      },
    };
    //@ts-ignore
    const response = yield call(axios, requestConfig);
    // console.log(response.data);
    const { shopOrders=[], success=false, totalCount=0, pageSize=0  } = response.data;
    let orders = [];
    if(success){
      // console.log("orders", orders);
      yield put(getAllOrdersSuccess({shopOrders: response.data?.shopOrders, totalCount, pageSize, }));
    }
  } catch (e) {
    console.log(e);
    let errorMessage = "";
    if (e.response && e.response.data) {
      errorMessage = e.response.data;
      //@ts-ignore
      if (errorMessage.errorInfo && errorMessage.errorInfo.message) {
        //@ts-ignore
        errorMessage = errorMessage.errorInfo.message;
      }
    }
    yield put(getAllOrdersFail(errorMessage));
  }
}

function* ordersSaga() {
  yield takeEvery(GET_ALL_ORDERS, getAllOrdersSaga);
}

export default ordersSaga;
