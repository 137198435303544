export const GET_ALL_ORDERS_TRIGGER = 'GET_ALL_ORDERS_TRIGGER'
export const GET_ALL_ORDERS = 'GET_ALL_ORDERS_'
export const GET_ALL_ORDERS_SUCCESS = 'GET_ALL_ORDERS_SUCCESS'
export const GET_ALL_ORDERS_FAIL = 'GET_ALL_ORDERS_FAIL'

export const CLEAR = 'ORDER/CLEAR'

export function getAllOrdersTrigger(){
    return {
        type : GET_ALL_ORDERS_TRIGGER
    }
}

export function getAllOrders(filterData){
    return {
        type : GET_ALL_ORDERS,
        payload: { filterData }
    }
}

export function getAllOrdersSuccess(orders){
    return {
        type : GET_ALL_ORDERS_SUCCESS,
        payload : { orders }
    }
}

export function getAllOrdersFail(errorMessage){
    return {
        type : GET_ALL_ORDERS_FAIL,
        payload : {
            errorMessage
        }
    }
}

export function clear(){
    return {
        type : CLEAR
    }
}