import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import "animate.css/animate.min.css";
import App from "next/app";
import Head from "next/head";
import Router from "next/router";
import { Provider } from "react-redux";
import "react-slidedown/lib/slidedown.css";
import { ToastProvider } from "react-toast-notifications";
import { persistStore } from "redux-persist";
import "swiper/swiper-bundle.css";
import "../assets/css/flaticon.css";
import "../assets/scss/style.scss";
import theme from "../assets/_theme";
import withReduxStoreV2 from "../lib/with-redux-store";
import { fetchProducts } from "../redux/actions/productActions";
import {
  logout,
  updateIdTokenRequest,
  updateIdTokenSuccess,
} from "../redux/actions/userActions";
import { Spinner } from "@chakra-ui/react";
import "../styles/globals.css";
import "@elastic/eui/dist/eui_theme_light.css";
import { EuiProvider } from "@elastic/eui";

import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "../../firebase";

class MyApp extends App {
  constructor(props) {
    super(props);
    Router.events.on("routeChangeStart", () => {
      this.setState({ loader: true });
    });

    Router.events.on("routeChangeComplete", () => {
      this.setState({ loader: false });
    });
    this.state = {
      loader: false,
    };

    //@ts-ignore
    this.persistor = persistStore(props.reduxStore);

    props.reduxStore.dispatch(fetchProducts());

    if (typeof window !== "undefined") {
      onAuthStateChanged(firebaseAuth, (user) => {
        if (user) {
          props.reduxStore.dispatch(updateIdTokenSuccess(user.accessToken));
        } else {
          props.reduxStore.dispatch(logout());
          if (localStorage.getItem("cart")) {
            const localCart = JSON.parse(localStorage.getItem("cart"));
          }
        }
      });
    }
  }

  render() {
    //@ts-ignore
    const { Component, pageProps, reduxStore } = this.props;

    return (
      <>
        <Head>
          <title>Okwi Shop by OKWI</title>
        </Head>
        <EuiProvider colorMode='light'>
          <ChakraProvider theme={theme}>
            <CSSReset />
            <ToastProvider placement='bottom-left'>
              <Provider store={reduxStore}>
                {this.state.loader ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)",
                      zIndex: 9999,
                    }}
                  >
                    <Spinner
                      thickness='4px'
                      speed='0.65s'
                      emptyColor='gray.200'
                      color='orange.500'
                      size='xl'
                    />
                  </div>
                ) : (
                  <Component {...pageProps} />
                )}
              </Provider>
            </ToastProvider>
          </ChakraProvider>
        </EuiProvider>
      </>
    );
  }
}

export default withReduxStoreV2(MyApp);

