export const REGISTER_USER_TRIGGER = "REGISTER_USER_TRIGGER";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";
export const LOGIN_LOADING_FALSE = "LOGIN_LOADING_FALSE";

export const LOGIN_USER_TRIGGER = "LOGIN_USER_TRIGGER";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export const GET_USER_DETAILS_TRIGGER = "GET_USER_DETAILS_TRIGGER";
export const GET_USER_DETAILS = "GET_USER_DETAILS_";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";

export const UPDATE_USER_DETAILS_TRIGGER = "UPDATE_USER_DETAILS_TRIGGER";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS_";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAIL = "UPDATE_USER_DETAILS_FAIL";

export const UPDATE_ID_TOKEN_REQUEST = "UPDATE_ID_TOKEN_REQUEST";
export const UPDATE_ID_TOKEN_SUCCESS = "UPDATE_ID_TOKEN_SUCCESS";

export const CHECKOUT_TRIGGER = "CHECKOUT_TRIGGER";
export const CHECKOUT = "CHECKOUT";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAIL = "CHECKOUT_FAIL";

export const LOGOUT = "LOGOUT";

export const CLEAR = "USERS/CLEAR";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const GUEST_CHECKOUT = "GUEST_CHECKOUT";
export const CUSTOM_CHECKOUT = "CUSTOM_CHECKOUT";