import {
  REGISTER_USER_FAIL,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_TRIGGER,
  LOGIN_USER_FAIL,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_TRIGGER,
  CLEAR,
  LOGOUT,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_TRIGGER,
  GET_USER_DETAILS_FAIL,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_TRIGGER,
  UPDATE_USER_DETAILS_FAIL,
  UPDATE_ID_TOKEN_SUCCESS,
  CHECKOUT_FAIL,
  CHECKOUT_SUCCESS,
  CHECKOUT_TRIGGER,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
} from "../types";

const initState = {
  registerLoading: false,
  profileData: {
    firstName: undefined,
    lastName: undefined,
    uid: undefined,
    email: undefined,
  },
  customToken: undefined,
  registerFailed: false,

  loginLoading: false,
  loginFailed: false,

  getUserDetailsLoading: false,
  getUserDetailsFailed: false,

  updateUserDetailsLoading: false,
  updateUserDetailsFailed: false,
  updateUserDetailsSuccess: false,

  checkoutSuccess: false,
  checkoutLoading: false,
  checkoutFailed: false,
  checkoutData: {},

  errorMessage: "",
  successMessage: "",
  authenticated: false,
  resetSuccess: false,
  resetFailed: false,
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case REGISTER_USER_TRIGGER:
      return {
        ...state,
        registerLoading: true,
      };
    case REGISTER_USER_SUCCESS:
      const { customToken, idToken, profileData } = action.payload;
      return {
        ...state,
        authenticated: true,
        registerLoading: false,
        customToken,
        profileData,
        idToken,
      };
    case REGISTER_USER_FAIL: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        registerLoading: false,
        registerFailed: true,
        errorMessage,
      };
    }
    case LOGIN_USER_TRIGGER:
      return {
        ...state,
        loginLoading: true,
      };
    case LOGIN_USER_SUCCESS: {
      const { customToken, idToken } = action.payload;
      return {
        ...state,
        authenticated: true,
        loginLoading: false,
        customToken,
        idToken,
      };
    }
    case LOGIN_USER_FAIL: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        loginLoading: false,
        loginFailed: true,
        errorMessage,
      };
    }
    case GET_USER_DETAILS_TRIGGER:
      return {
        ...state,
        getUserDetailsLoading: true,
      };
    case GET_USER_DETAILS_SUCCESS: {
      const { profileData } = action.payload;
      const productCustomPrices = {};
      const variantCustomPrices = {};
      profileData.customPricing.map((priceItem) => {
        if (priceItem.itemType === "product") {
          productCustomPrices[priceItem.itemId] = { ...priceItem };
        } else {
          variantCustomPrices[priceItem.itemId] = { ...priceItem };
        }
      });
      delete profileData["customPricing"];
      return {
        ...state,
        getUserDetailsLoading: false,
        profileData: {
          ...state.profileData,
          ...profileData,
          variantCustomPrices,
          productCustomPrices,
        },
      };
    }
    case GET_USER_DETAILS_FAIL: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        getUserDetailsLoading: false,
        getUserDetailsFailed: true,
        errorMessage,
      };
    }
    case UPDATE_USER_DETAILS_TRIGGER: {
      return {
        ...state,
        updateUserDetailsLoading: true,
      };
    }
    case UPDATE_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        updateUserDetailsLoading: false,
        updateUserDetailsSuccess: true,
        ...action.payload,
      };
    }
    case UPDATE_USER_DETAILS_FAIL: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        updateUserDetailsLoading: false,
        updateUserDetailsFailed: true,
        errorMessage,
      };
    }
    case CLEAR:
      return {
        ...state,
        registerFailed: false,
        errorMessage: "",
        loginFailed: false,
        loginLoading: false,
        getUserDetailsFailed: false,
        updateUserDetailsFailed: false,
        updateUserDetailsSuccess: false,
        checkoutSuccess: false,
        checkoutLoading: false,
        checkoutFailed: false,
        resetSuccess: false,
        resetFailed: false,
        successMessage: "",
      };
    case LOGOUT:
      return initState;
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        resetSuccess: true,
        resetFailed: false,
        ...action?.payload?.data,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loginLoading: false,
        resetSuccess: false,
        resetFailed: true,
        ...action?.payload?.data,
      };
    case UPDATE_ID_TOKEN_SUCCESS: {
      const { idToken } = action.payload;
      return {
        ...state,
        idToken,
      };
    }
    case CHECKOUT_TRIGGER:
      return {
        ...state,
        checkoutLoading: true,
      };
    case CHECKOUT_SUCCESS: {
      const { checkoutDetails } = action.payload;
      return {
        ...state,
        checkoutLoading: false,
        checkoutDetails,
        checkoutSuccess: true,
      };
    }
    case CHECKOUT_FAIL: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        checkoutLoading: false,
        checkoutFailed: true,
        errorMessage,
      };
    }
    default:
      return state;
  }
};
export default userReducer;
