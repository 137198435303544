import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  GET_ALL_REVIEW,
  UPDATE_REVIEW,
  CREATE_REVIEW,
  createReviewSuccess,
  createReviewFail,
  updateReviewSuccess,
  updateReviewFail,
  getAllReviewSuccess,
  getAllReviewFail,
} from "../actions/reviewActions";
import axios, { AxiosRequestConfig } from "axios";
import { generateIdToken } from "./userSaga";

function* createReviewSaga(action) {
  try {
    const { order_id, product_id, review, stars, image } = action.payload;

    const userData = yield select((state) => state.userData);
    const { authenticated, profileData } = userData;

    const { customToken } = userData;
    const idToken = yield generateIdToken();

    const { uid } = profileData;
    let formdata = new FormData();

    if (image.file) formdata.append("images", image.file, image.path);

    formdata.append("product_id", product_id);
    formdata.append("customer_id", uid);
    formdata.append("order_id", order_id);
    formdata.append("review", review);
    formdata.append("stars", stars);

    const requestConfig = {
      method: "POST",
      url: `${process.env.NEXT_PUBLIC_API_BASE}product/reviews`,
      headers: {
        Authorization: idToken,
      },
      data: formdata,
    };
    //@ts-ignore
    const response = yield call(axios, requestConfig);
    yield put(createReviewSuccess(product_id, response.data.reviews));
  } catch (e) {
    console.log(e);
    let errorMessage = "";
    if (e.response && e.response.data) {
      errorMessage = e.response.data;
      //@ts-ignore
      if (errorMessage.errorInfo && errorMessage.errorInfo.message) {
        //@ts-ignore
        errorMessage = errorMessage.errorInfo.message;
      }
    }
    yield put(createReviewFail(errorMessage));
  }
}

function* updateReviewSaga(action) {
  try {
    const userData = yield select((state) => state.userData);
    const { authenticated } = userData;
    const { customToken } = userData;
    const idToken = yield generateIdToken();
    const {
      id,
      reviewLine1,
      reviewLine2,
      postcode,
      townCity,
      country,
      review_type,
      _storage_type,
    } = action.payload;
    const requestConfig = {
      method: "PATCH",
      url: `${process.env.NEXT_PUBLIC_API_BASE}review/${id}`,
      headers: {
        Authorization: idToken,
      },
      data: {
        review: {
          reviewLine1,
          reviewLine2,
          postcode,
          townCity,
          country,
          review_type,
          _storage_type,
        },
      },
    };
    //@ts-ignore
    const response = yield call(axios, requestConfig);
    yield put(updateReviewSuccess(response.data));
  } catch (e) {
    console.log(e);
    let errorMessage = "";
    if (e.response && e.response.data) {
      errorMessage = e.response.data;
      //@ts-ignore
      if (errorMessage.errorInfo && errorMessage.errorInfo.message) {
        //@ts-ignore
        errorMessage = errorMessage.errorInfo.message;
      }
    }
    yield put(updateReviewFail(errorMessage));
  }
}

function* getAllReviewSaga(action) {
  try {
    const userData = yield select((state) => state.userData);
    const { authenticated } = userData;
    const { customToken } = userData;
    const idToken = yield generateIdToken();

    const { product_id } = action.payload;

    const requestConfig = {
      method: "GET",
      url: `${process.env.NEXT_PUBLIC_API_BASE}product/reviews/${product_id}`,
      headers: {
        Authorization: idToken,
      },
    };
    //@ts-ignore
    const response = yield call(axios, requestConfig);
    yield put(getAllReviewSuccess(product_id, response.data.reviews));
  } catch (e) {
    console.log(e);
    let errorMessage = "";
    if (e.response && e.response.data) {
      errorMessage = e.response.data;
      //@ts-ignore
      if (errorMessage.errorInfo && errorMessage.errorInfo.message) {
        //@ts-ignore
        errorMessage = errorMessage.errorInfo.message;
      }
    }
    yield put(getAllReviewFail(errorMessage));
  }
}

function* reviewSaga() {
  yield takeEvery(CREATE_REVIEW, createReviewSaga);
  yield takeEvery(UPDATE_REVIEW, updateReviewSaga);
  yield takeEvery(GET_ALL_REVIEW, getAllReviewSaga);
}

export default reviewSaga;
