import {
  REGISTER_USER_TRIGGER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOGIN_USER_TRIGGER,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  GET_USER_DETAILS_TRIGGER,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  UPDATE_USER_DETAILS_TRIGGER,
  UPDATE_USER_DETAILS,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAIL,
  UPDATE_ID_TOKEN_REQUEST,
  UPDATE_ID_TOKEN_SUCCESS,
  CHECKOUT_TRIGGER,
  CHECKOUT,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAIL,
  LOGOUT,
  CLEAR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  GUEST_CHECKOUT,
  CUSTOM_CHECKOUT,
  LOGIN_LOADING_FALSE,
} from "../types";

export function registerUserTrigger() {
  return {
    type: REGISTER_USER_TRIGGER,
  };
}

export function registerUser(email, password, firstName, lastName) {
  return {
    type: REGISTER_USER,
    payload: {
      email,
      password,
      firstName,
      lastName,
    },
  };
}

export function forgotPasswordHandler(email) {
  return {
    type: FORGOT_PASSWORD,
    payload: {
      email,
    },
  };
}

export function forgotPasswordSuccess(data) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: {
      data,
    },
  };
}

export function forgotPasswordFailure(data) {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    payload: {
      data,
    },
  };
}

export function registerUserSuccess(customToken, idToken, profileData) {
  return {
    type: REGISTER_USER_SUCCESS,
    payload: {
      customToken,
      idToken,
      profileData,
    },
  };
}

export function registerUserFail(errorMessage) {
  return {
    type: REGISTER_USER_FAIL,
    payload: {
      errorMessage,
    },
  };
}

export function loginUserTrigger() {
  return {
    type: LOGIN_USER_TRIGGER,
  };
}

export function loginUser(email, password, customToken = null) {
  return {
    type: LOGIN_USER,
    payload: {
      email,
      password,
      customToken,
    },
  };
}

export function loginUserSuccess(customToken, idToken, profileData) {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: {
      customToken,
      idToken,
      profileData,
    },
  };
}

export function loginUserFail(errorMessage) {
  return {
    type: LOGIN_USER_FAIL,
    payload: {
      errorMessage,
    },
  };
}

export function getUserDetailsTrigger() {
  return {
    type: GET_USER_DETAILS_TRIGGER,
  };
}

export function getUserDetails() {
  return {
    type: GET_USER_DETAILS,
  };
}

export function getUserDetailsSuccess(profileData) {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    payload: { profileData },
  };
}

export function getUserDetailsFail(errorMessage) {
  return {
    type: GET_USER_DETAILS_FAIL,
    payload: {
      errorMessage,
    },
  };
}

export function updateUserTrigger() {
  return {
    type: UPDATE_USER_DETAILS_TRIGGER,
  };
}

export function updateUser(email, firstName, lastName, password) {
  return {
    type: UPDATE_USER_DETAILS,
    payload: {
      email,
      firstName,
      lastName,
      password,
    },
  };
}

export function updateUserSuccess(profileData) {
  return {
    type: UPDATE_USER_DETAILS_SUCCESS,
    payload: {
      profileData,
    },
  };
}

export function updateUserFail(errorMessage) {
  return {
    type: UPDATE_USER_DETAILS_FAIL,
    payload: {
      errorMessage,
    },
  };
}

export function clear() {
  return {
    type: CLEAR,
  };
}

export function logout() {
  return {
    type: "LOGOUT_TRIGGER",
  };
}

export function final_logout() {
  return {
    type: LOGOUT,
  };
}

export function updateIdTokenRequest() {
  return {
    type: UPDATE_ID_TOKEN_REQUEST,
  };
}

export function updateIdTokenSuccess(idToken) {
  return {
    type: UPDATE_ID_TOKEN_SUCCESS,
    payload: {
      idToken,
    },
  };
}

export function checkoutTrigger() {
  return {
    type: CHECKOUT_TRIGGER,
  };
}

export function checkoutSuccess(checkoutDetails) {
  return {
    type: CHECKOUT_SUCCESS,
    payload: {
      checkoutDetails,
    },
  };
}

export function checkoutFail(errorMessage) {
  return {
    type: CHECKOUT_FAIL,
    payload: {
      errorMessage,
    },
  };
}
