
//! Must match calculation for backend to get correct price for payment supplier
export const calculateCartAmountInt = (priceFloat) => {
  return parseInt(roundToTwo(priceFloat) * 100);
};

//! Must match calculation for backend to get correct price for payment supplier
export const calculateCurrentPrice = (
  variant,
  quantity,
  coupon = null,
  cartItems = null
) => {
  //Start at a high number so if there is an error in the priceRangeArray it will not calculate price as 0
  let price = 9999;
  const priceRange = variant.priceRangeArray;

  //Total discount is based on all items that have the same parentId (product)
  let totalQuantity = quantity;
  if (cartItems) {
    totalQuantity = cartItems.reduce((cumulativeQuantity, currentItem) => {
      const currentQuantity =
        variant.parentId === currentItem.selectedVariant.parentId
          ? currentItem.quantity
          : 0;
      return cumulativeQuantity + currentQuantity;
    }, 0);
  }

  priceRange &&
    priceRange
      .sort((a, b) => (parseInt(a.min) > parseInt(b.min) ? 1 : -1))
      .map((range) =>
        (range.max === "MAX" || totalQuantity <= range.max) &&
        totalQuantity >= range.min
          ? (price = calculatePriceWithVAT(variant, range.price))
          : ""
      );

  let discountPrice = price;

  if (coupon) {
    discountPrice = calculateDiscountPrice({
      coupon,
      price,
      item: variant,
      quantity: totalQuantity,
    });
  }

  return { price, discountPrice };
};

export const calculateDiscountPrice = ({ coupon, price, item, quantity }) => {
  //1. Check if coupon is valid for this item.
  if (
    (coupon.linkingType = "category" && coupon.linkingId === item.categoryId) ||
    (coupon.linkingType = "product" && coupon.linkingId === item.parentId)
  ) {
    if (
      coupon.hasMinimumOrderQuantity &&
      quantity < coupon.minimumOrderQuantity
    ) {
      return price;
    }

    switch (coupon.discountType) {
      case "percentage":
        // Percentage - Set price to a percentage of the price + vat
        return price - (price / 100) * parseFloat(coupon.discountValue);
      case "fixed":
        // Fixed - Set price to a specific price + vat
        return parseFloat(coupon.discountValue);
      default:
        return price;
    }
  }

  return price;
};

//! Must match calculation for backend to get correct price for payment supplier
export const calculatePriceWithVAT = (variant, price) => {
  let newPrice = price;

  if (variant.VAT) {
    const VAT = parseFloat(variant.VAT);
    newPrice = price + (price * VAT) / 100;
  }

  return newPrice;
};

//! Must match calculation for backend to get correct price for payment supplier
export const roundToTwo = (num) => {
  //https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary#comment24719818_11832950
  //This will always round up to the closest second decimal place
  return +(Math.round(num + "e+2") + "e-2");
};
