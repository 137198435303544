import { extendTheme } from "@chakra-ui/react";

const colors = {
  brand: {
    900: "#1c0004",
    800: "#55000b",
    700: "#8e0013",
    600: "#c6001a",
    500: "#ff0022",
    400: "#ff3953",
    300: "#ff7184",
    200: "#ffaab5",
    100: "#ffe3e6",
  },
};

const theme = extendTheme({ colors });

export default theme;
