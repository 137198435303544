import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  GET_ALL_ADDRESSES,
  UPDATE_ADDRESS,
  CREATE_ADDRESS,
  createAddressSuccess,
  createAddressFail,
  updateAddressSuccess,
  updateAddressFail,
  getAllAddressSuccess,
  getAllAddressFail,
} from "../actions/addressActions";
import axios from "axios";
import { generateIdToken } from "./userSaga";

function* createAddressSaga(action) {
  try {
    // console.log('address saga', action);
    const {
      addressLine1,
      addressLine2,
      postcode,
      townCity,
      country,
      address_type,
      pageName='',
    } = action.payload;
    const userData = yield select((state) => state.userData);
    const addressData = yield select((state) => state.addressData);
    const { customToken, authenticated } = userData;
    if (authenticated && pageName ==='checkout') {
      const idToken = yield generateIdToken();
      const address = {
        addressLine1,
        addressLine2,
        postcode,
        townCity,
        country,
        address_type,
      };
      const requestConfig = {
        method: "POST",
        url: `${process.env.NEXT_PUBLIC_API_BASE}address`,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        data: {
          address,
        },
      };
      //@ts-ignore
      const response = yield call(axios, requestConfig);
      const addressDataRes = response.data.addressData;
      let addRessDataResponse = addressDataRes.map((data) => {
        const newData =  {...data, addressLine1: data.lineOne, addressLine2: data.lineTwo, townCity: data.city}
        delete newData.lineOne, delete newData.lineTwo, delete newData.city;
        return newData;
      });
      let addData = [];
      if (addressData?.addresses.length >= 1 ) {
        addData = [0,1].map((index) => {
          const row = addressData?.addresses[index] || {};
          if (row && row.address_type && 
            row.address_type === addRessDataResponse[0]?.address_type
          ) {
            return addRessDataResponse[0];
          }
          else if(row && row.address_type &&
            row.address_type !== addRessDataResponse[0]?.address_type){
            return row;
          }
            return addRessDataResponse[0];
        });
      } else {
        addData?.push(...addRessDataResponse);
      }
      if (response.data.success) 
      {
        yield put(createAddressSuccess({addresses : addData}));
      }
    }
    else if(!authenticated && pageName ==='guest-checkout'){
      const address = {
        addressLine1,
        addressLine2,
        postcode,
        townCity,
        country,
        address_type,
      };
      const addressDataRes = address;
      let addData = [];
      if (addressData?.addresses.length >= 1 ) {
        addData = [0,1].map((index) => {
          const row = addressData?.addresses[index] || {};
          if (row && row.address_type && 
            row.address_type === addressDataRes?.address_type
          ) {
            return addressDataRes;
          }
          else if(row && row.address_type &&
            row.address_type !== addressDataRes?.address_type){
            return row;
          }
            return addressDataRes;
        });
      } else {
        addData?.push(address);
      }
        yield put(createAddressSuccess({addresses : addData}));
    }
  } catch (e) {
    console.log(e);
    let errorMessage = "";
    if (e.response && e.response.data) {
      errorMessage = e.response.data;
      //@ts-ignore
      if (errorMessage.errorInfo && errorMessage.errorInfo.message) {
        //@ts-ignore
        errorMessage = errorMessage.errorInfo.message;
      }
    }
    yield put(createAddressFail(errorMessage));
  }
}

function* updateAddressSaga(action) {
  try {
    const userData = yield select((state) => state.userData);
    const addressData = yield select((state) => state.addressData);
    const { customToken, authenticated } = userData;
    const dtToUpdate = action.payload;
    const {
      id,
      addressLine1,
      addressLine2,
      postcode,
      townCity,
      country,
      address_type,
      _storage_type = "",
      pageName='',
    } = dtToUpdate;
    if (authenticated && pageName === 'checkout') {
      const idToken = yield generateIdToken();
      const requestConfig = {
        method: "POST",
        url: `${process.env.NEXT_PUBLIC_API_BASE}address/${id}`,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        data: {
          address: {
            addressLine1,
            addressLine2,
            postcode,
            townCity,
            country,
            address_type,
            _storageType: _storage_type,
          },
        },
      };
      //@ts-ignore
      const response = yield call(axios, requestConfig);
      if (response?.data.success) {
        const addData = [];
        addressData.addresses.map((address) => {
          if (address.id === dtToUpdate.id) {
            address.addressLine1 = dtToUpdate.addressLine1;
            address.addressLine2 = dtToUpdate.addressLine2;
            address.townCity = dtToUpdate.townCity;
            address.country = dtToUpdate.country;
            address.county = dtToUpdate.county;
            addData.push(address);
            return;
          }
          addData.push(address);
        });
        yield put(updateAddressSuccess({ addresses : addData}));
      }
    } else {
      let addressList = [...addressData.addresses];
      addressList = addressList.map((address) => {
          if(dtToUpdate.address_type === address.address_type){
            return {
              addressLine1: dtToUpdate.addressLine1,
              addressLine2: dtToUpdate.addressLine2,
              postcode: dtToUpdate.postcode,
              townCity: dtToUpdate.townCity,
              country: dtToUpdate.country,
              address_type: dtToUpdate.address_type,
              _storage_type,
            };
          }
          return address;
      });
      // console.log(addressList);
      yield put(updateAddressSuccess({ addresses : addressList}));
    }
  } catch (e) {
    console.log(e);
    let errorMessage = "";
    if (e.response && e.response.data) {
      errorMessage = e.response.data;
      //@ts-ignore
      if (errorMessage.errorInfo && errorMessage.errorInfo.message) {
        //@ts-ignore
        errorMessage = errorMessage.errorInfo.message;
      }
    }
    yield put(updateAddressFail(errorMessage));
  }
}

function* getAllAddressSaga(action) {
  try {
    const userData = yield select((state) => state.userData);
    const { customToken, authenticated } = userData;
    const idToken = yield generateIdToken();
    const requestConfig = {
      method: "GET",
      url: `${process.env.NEXT_PUBLIC_API_BASE}address`,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    //@ts-ignore
    const response = yield call(axios, requestConfig);
    const { success = "", addresses = [] } = response.data;
    // console.log('getalladress', addresses);
    let addRessData = addresses.map((data) => {
      const newData =  {...data, addressLine1: data.lineOne, addressLine2: data.lineTwo, townCity: data.city}
      delete newData.lineOne, delete newData.lineTwo, delete newData.city;
      return newData;
    });
    if (success) yield put(getAllAddressSuccess(addRessData));
  } catch (e) {
    console.log(e);
    let errorMessage = "";
    if (e.response && e.response.data) {
      errorMessage = e.response.data;
      //@ts-ignore
      if (errorMessage.errorInfo && errorMessage.errorInfo.message) {
        //@ts-ignore
        errorMessage = errorMessage.errorInfo.message;
      }
    }
    yield put(getAllAddressFail(errorMessage));
  }
}

function* addressSaga() {
  yield takeEvery(CREATE_ADDRESS, createAddressSaga);
  yield takeEvery(UPDATE_ADDRESS, updateAddressSaga);
  yield takeEvery(GET_ALL_ADDRESSES, getAllAddressSaga);
}

export default addressSaga;
