export const CREATE_REVIEW_TRIGGER = 'CREATE_REVIEW_TRIGGER'
export const CREATE_REVIEW = 'CREATE_REVIEW'
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS'
export const CREATE_REVIEW_FAIL = 'CREATE_REVIEW_FAIL'

export const UPDATE_REVIEW_TRIGGER = 'UPDATE_REVIEW_TRIGGER'
export const UPDATE_REVIEW = 'UPDATE_REVIEW'
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS'
export const UPDATE_REVIEW_FAIL = 'UPDATE_REVIEW_FAIL'

export const GET_ALL_REVIEW_TRIGGER = 'GET_ALL_REVIEW_TRIGGER'
export const GET_ALL_REVIEW = 'GET_ALL_REVIEW_'
export const GET_ALL_REVIEW_SUCCESS = 'GET_ALL_REVIEW_SUCCESS'
export const GET_ALL_REVIEW_FAIL = 'GET_ALL_REVIEW_FAIL'

export const CLEAR = 'REVIEW/CLEAR'

export function createReviewTrigger(){
    return {
        type : CREATE_REVIEW_TRIGGER
    }
}

export function createReview(order_id, product_id, review, stars, image){
    return {
        type : CREATE_REVIEW,
        payload : {
            order_id, product_id, review, stars, image
        }
    }
}

export function createReviewSuccess(product_id, reviews){
    return {
        type : CREATE_REVIEW_SUCCESS,
        payload : {
            product_id,
            reviews
        }
    }
}

export function createReviewFail(errorMessage){
    return {
        type : CREATE_REVIEW_FAIL,
        payload : {
            errorMessage
        }
    }
}

export function updateReviewTrigger(){
    return {
        type : UPDATE_REVIEW_TRIGGER
    }
}

export function updateReview(review_id ,order_id, product_id, review, stars, image){
    return {
        type : UPDATE_REVIEW,
        payload : {
            review_id ,order_id, product_id, review, stars, image
        }
    }
}

export function updateReviewSuccess(product_id, review){
    return {
        type : UPDATE_REVIEW_SUCCESS,
        payload : {
            product_id, review
        }
    }
}

export function updateReviewFail(errorMessage){
    return {
        type : UPDATE_REVIEW_FAIL,
        payload : {
            errorMessage
        }
    }
}

export function getAllReviewTrigger(){
    return {
        type : GET_ALL_REVIEW_TRIGGER
    }
}

export function getAllReview(product_id){
    return {
        type : GET_ALL_REVIEW,
        payload : { product_id }
    }
}

export function getAllReviewSuccess(product_id, reviews){
    return {
        type : GET_ALL_REVIEW_SUCCESS,
        payload : { product_id, reviews }
    }
}

export function getAllReviewFail(errorMessage){
    return {
        type : GET_ALL_REVIEW_FAIL,
        payload : {
            errorMessage
        }
    }
}

export function clear(){
    return {
        type : CLEAR
    }
}

export function logout(){
    return {
        type : LOGOUT
    }
}