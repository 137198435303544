import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer } from "redux-persist";
import storage from "./storage";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers/rootReducer";
import rootSaga from "./sagas/rootSaga";
import thunk from "redux-thunk";

import {createWrapper} from 'next-redux-wrapper';

const persistConfig = {
  key: "primary",
  storage,
  blacklist: [
    "productData",
    "paymentData",
    "addressData",
    "ordersData",
    "reviewData",
    "couponData",
    "checkoutData",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, thunk];

export function initializeStore() {
  let store;
  if (process.env.NODE_ENV === "development") {
    store = createStore(
      persistedReducer,
      composeWithDevTools(applyMiddleware(...middlewares))
    );
  } else {
    store = createStore(
      persistedReducer,
      compose(applyMiddleware(...middlewares))
    );
  }
  //@ts-ignore
  store.sagaTask = sagaMiddleware.run(rootSaga);
  return store;
}

export const wrapper = createWrapper(initializeStore, {debug: false});
