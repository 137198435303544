import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import userReducer from "./userReducer";
import addressReducer from "./addressReducer";
import ordersReducer from "./ordersReducer";
import reviewReducer from "./reviewReducer";
import checkoutReducer from "./checkoutReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  productData: productReducer,
  cartData: cartReducer,
  userData: userReducer,
  addressData: addressReducer,
  ordersData: ordersReducer,
  reviewData: reviewReducer,
  checkoutData: checkoutReducer,
});

export default rootReducer;
