import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateEmail,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBNUCvgzquCLrL52TP6oBjFsXQkOAW7PX8",
  authDomain: "okwi-c25e5.firebaseapp.com",
  databaseURL: "https://okwi-c25e5.firebaseio.com",
  projectId: "okwi-c25e5",
  storageBucket: "okwi-c25e5.appspot.com",
  messagingSenderId: "861035364247",
  appId: "1:861035364247:web:be9587d3b61455ed39ced2",
  measurementId: "G-7Y7QJ04L1G",
};

const app = initializeApp(firebaseConfig);

const firebaseAuth = getAuth();

export {
  firebaseAuth,
  app,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateEmail,
};
