import { 
  CREATE_REVIEW_FAIL,
  CREATE_REVIEW_SUCCESS,
  CREATE_REVIEW_TRIGGER,
  UPDATE_REVIEW_FAIL,
  UPDATE_REVIEW_SUCCESS,
  UPDATE_REVIEW_TRIGGER,
  CLEAR,
  GET_ALL_REVIEW_SUCCESS,
  GET_ALL_REVIEW_TRIGGER,
  GET_ALL_REVIEW_FAIL,
} from "../actions/reviewActions";

import {
  LOGOUT,
} from "../actions/userActions";

const initState = {
  getAllReviewsLoading : false,
  reviews : {} ,
  getAllReviewsFailed : false,

  updateReviewLoading : false,
  updateReviewFailed : false,
  updateReviewSuccess: false,

  createReviewLoading : false,
  createReviewFailed : false,
  createReviewSuccess: false,

  errorMessage : '',
};

const userReducer = (state = initState, action) => {
  if (action.type === CREATE_REVIEW_TRIGGER){
    return {
      ...state,
      createReviewLoading : true,
    }
  }
  if (action.type === CREATE_REVIEW_SUCCESS) {
    const { reviews, product_id } = action.payload
    return {
      ...state,
      createReviewLoading : false,
      createReviewSuccess: true,
      reviews : {
        ...state.reviews,
        [product_id]: reviews
      }
    };
  }
  if (action.type === CREATE_REVIEW_FAIL) {
    const { errorMessage } = action.payload
    return {
      ...state,
      createReviewLoading : false,
      createReviewFailed : true,
      errorMessage
    };
  }

  if (action.type === UPDATE_REVIEW_TRIGGER){
    return {
      ...state,
      updateReviewLoading : true,
    }
  }
  if (action.type === UPDATE_REVIEW_SUCCESS) {
    const { review, product_id } = action.payload
    return {
      ...state,
      updateReviewSuccess: true,
      updateReviewLoading : false,
      reviews : {
        ...state.reviews,
        [product_id]: state.reviews[product_id] ? [
          ...state.reviews[product_id],
          review
        ] : [ review ]
      }
    };
  }
  if (action.type === UPDATE_REVIEW_FAIL) {
    const { errorMessage } = action.payload
    return {
      ...state,
      updateReviewLoading : false,
      updateReviewFailed : true,
      errorMessage
    };
  }

  if(action.type === GET_ALL_REVIEW_TRIGGER) {
    return {
      ...state,
      getAllReviewsLoading : true,
    }
  }
  if(action.type === GET_ALL_REVIEW_SUCCESS) {

    const { product_id, reviews } = action.payload;

    return {
      ...state,
      getAllReviewsLoading : false,
      reviews: {
        ...state.reviews,
        [product_id]: reviews
      }
    }
  }
  if (action.type === GET_ALL_REVIEW_FAIL) {
    const { errorMessage } = action.payload
    return {
      ...state,
      getAllReviewsLoading : false,
      getAllReviewsFailed : true,
      errorMessage
    };
  }

  if (action.type === CLEAR) {
    return {
      ...state,
      getAllReviewsFailed : false,
      updateReviewFailed : false,
      createReviewFailed : false,
      updateReviewSuccess: false,
      createReviewSuccess: false,
      updateReviewLoading: false,
      createReviewLoading: false,
      errorMessage : '',
    }
  }

  // if (action.type === LOGOUT){
  //   return initState
  // }
  
  return state;
};

export default userReducer;
